import React from "react";
import "../../styles/components/search.css"
import Loader from "../navigation/Loader";
import { checkForUserPermissions } from "../shared/utilities";

export const SearchResults = ({resultList, searchIsActive, isLoading, isAdmin, user}) => {
  const contentDivs = resultList?.content?.map((r, i) => 
    <a target="_blank" href={r.blobUrlAccessible} className="siteSearchResult" key={i+"content"}>
        <div className="siteSearchResult-left">
        <i className="fa-light fa-book"></i>
          {r.description}
        </div>
        <i className="fa-light fa-arrow-right siteSearchResult-arrow"></i>
    </a>
  );

  const contactDivs = resultList?.contacts?.map((r, i) => 
    (isAdmin || !r.hideContactFromOthers) &&
    <a href={`/contacts/${r.id}`} className="siteSearchResult" key={i+"contact"}>
        <div className="siteSearchResult-left">
            <i className="fa-sharp fa-solid fa-user"></i>
            <div className="siteSearchResult-text">
              <div>{`${r.firstName} ${r.lastName}`}</div>
              { r.relationToPlayer && <div className="searchResult-subtext">{ r.relationToPlayer } of { r.relatedPlayer.name }</div> }
              { r.alternateDescription && <div className="searchResult-subtext">{ r.alternateDescription }</div> }
            </div>
        </div>
        <i className="fa-light fa-arrow-right siteSearchResult-arrow"></i>
    </a>
  );

  const linkDivs = resultList?.searchableLinks?.map((r, i) => 

    checkForUserPermissions(r.viewablePermissions, user) && 
    <a href={r.url} className="siteSearchResult" key={i+"searchableLinks"}>
        <div className="siteSearchResult-left">
        <i className="fa-sharp fa-light fa-memo"></i>
          {r.linkName}
        </div>
        <i className="fa-light fa-arrow-right siteSearchResult-arrow"></i>
    </a>
    
  );


  return (
    isLoading ? setTimeout(() => <div>searching <Loader /></div>, 1000 ):
    <div className={`searchResultsContainer ${searchIsActive ? 'active' : ''}`}>
        {contentDivs}
        {contactDivs}
        {linkDivs}
    </div>
  );
};