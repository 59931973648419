import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const LoginPage = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const navigate = useNavigate();

useEffect(() => {
    const handleLogin = async () => {
        if (isAuthenticated)  {
            return navigate("/");
        }
        else {
            await loginWithRedirect({
                appState: {
                  returnTo: "/",
                },
                authorizationParams: {
                  prompt: "login",
                },
              });
        }
      };
    
      handleLogin();
}, [])

  return null;
};
