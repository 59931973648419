import React from "react";
import "../../styles/components/content.css"
import Loader from "../navigation/Loader";
import { Link, NavLink, useNavigate } from "react-router-dom";

export default function ContentBanner({content, canEdit = false}) {
    const navigate = useNavigate();
    function navToContentEdit(e) {
        e.preventDefault();
        e.stopPropagation();
        navigate(`/content/edit/${content.id}`);
    }

    return (
        content == null ? <div className="content-banner-placeholder"><Loader /></div> : 
        <div className="bannerIframeOuter">
            <a href={content.blobUrlAccessible} target="_blank" className="bannerDescription">
                <span className="bannerDescriptionText">{content.description}</span>
                <i className="fa-light fa-arrow-right"></i>
                {canEdit &&
                <div className="content-edit-btn">
                    <button onClick={navToContentEdit} className="btn">
                        Edit
                    </button>
                </div>
            }
            </a>
            <iframe scrolling="no" frameBorder="0" className="bannerIframe" src={content.blobUrlAccessible}></iframe>
        </div>
    )
}