import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { CodeSnippet } from "../components/code-snippet";
import Contact from "../components/contacts/contact";
import { PageLayout } from "../components/page-layout";
import { getContactById } from "../services/contact-service";
import { isUserSiteAdmin } from "../components/shared/utilities";

export const ProfilePage = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const isUserAdmin = isUserSiteAdmin(user);
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    const getProfileData = async () => {
      const token = await getAccessTokenSilently();
      const profileData = await getContactById(token, user.memberId);
      setProfile(profileData.data);
    }
    getProfileData();
  }, [])

  if (!user) {
    return null;
  }

  return (    
    <PageLayout>
      <Contact 
        contact={profile} 
        viewerIsAdmin={isUserAdmin} 
        showFullVersion={true} 
        viewingOwnProfile={true}
      />
    </PageLayout>
  );
};
