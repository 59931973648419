import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { MobileNavBarTab } from "./mobile-nav-bar-tab";
import { isUserSiteAdmin } from "../../shared/utilities";

export const MobileNavBarTabs = ({ handleClick }) => {
  const { isAuthenticated, user } = useAuth0();
  const isAdmin = isUserSiteAdmin(user);

  return (
    <div className="mobile-nav-bar__tabs">
      {isAuthenticated && (
        <>
          <MobileNavBarTab
            path="/profile"
            label="Profile"
            handleClick={handleClick}
          />
          <MobileNavBarTab path="/contacts/all" label="Contacts" />
          <MobileNavBarTab path="/content/all" label="Content" />
          { isAdmin &&
            <MobileNavBarTab
              path="/admin"
              label="Admin"
              handleClick={handleClick}
            />
          }
          <MobileNavBarTab
            path="/"
            label="Home"
          handleClick={handleClick}
      />
        </>
      )}
    </div>
  );
};
