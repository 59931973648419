import React, { useState, useEffect } from "react";
import { PageLayout } from "../components/page-layout";
import { MessageSender } from "../components/messaging/message-sender";

export const SendMessagePage = () => {
    return (
        <PageLayout>
            <>
                <div className="generic-title left">Send a Message</div>
                <MessageSender />
            </>
        </PageLayout>
    )
} 