import React, { useState, useEffect } from "react";


export const CreateContactField = (props) => {
    return (
        <div className={`form-fieldgroup ${props.className}`} style={props.style}>
            <label htmlFor={props.id}>{props.label}</label>
            <input placeholder={props.placeholder} value={props.value} onChange={props.handleValueChange} defaultChecked={props.value} name={props.propName} id={props.id} type={props.type ?? "text"} />
        </div>
    )
}