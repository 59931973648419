export const getAvailablePermissions = () => 
    [
        {
            value: "site:admin",
            label: "Site Admin"
        },
        {
            value: "view:jerseyform",
            label: "View Jersey Request Form"
        },
        {
            value: "IT:admin",
            label: "IT Admin"
        }
    ];

export const convertPermissionsForSubmit = (permissions) =>
    permissions?.map(p => p.value);

export const convertPermissionsForView = (permissionsDto) =>
    permissionsDto?.map(p => ({
        value: p, 
        label: getAvailablePermissions().find(perm => perm.value === p)?.label 
    })
);
