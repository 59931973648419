import { useAuth0 } from "@auth0/auth0-react";
import React, { useState, useEffect } from "react";
import { getAllPinnedPosts } from "../../services/pinnedpost-service";
import Loader from "../navigation/Loader";
import "../../styles/components/pinnedpost.css"

export default function PinnedPosts() {
    const { getAccessTokenSilently, user } = useAuth0();
    const [pinnedPosts, setPinnedPosts] = useState([]);
    const [isLoading, setLoaded] = useState(true);

    useEffect(() => {
        async function fetchContent() {
            const accessToken = await getAccessTokenSilently();
            const contentReturned = await getAllPinnedPosts(accessToken);            
            setLoaded(false);
            setPinnedPosts(contentReturned.data);
        }
        fetchContent();
    }, []);

    return (
        pinnedPosts && pinnedPosts?.map(p => <PinnedPost key={p.id} post={p} />)
    )
}

export function PinnedPost({post}) {  
    return (
        <a className="pinpost-outer" href={post.postLink}>
            <div className="pinpost-left">
                <i className="fa-solid fa-bell"></i>
            </div>
            <div className="pinpost-text">
                <div>{post.postName}</div>
                <div className="pinpost-subtext">{post.postSubName}</div>                
            </div>
            <div className="pinpost-right">
                <i className="fa-light fa-chevron-right"></i>
            </div>
        </a>
    )
}