import React, { useState, useEffect, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import "../../styles/components/content.css"
import Loader from "../navigation/Loader";
import  JerseyFormInputs from "./form-jersey-person-inputs";
import { getContactById } from "../../services/contact-service";
import { createGiftForm } from "../../services/form-service";
import toast from 'react-hot-toast';
import "../../styles/general.css";
import "../../styles/components/forms.css";

export default function GiftFormCreate() {
    const [jackets, setJacket] = useState([]);
    const { user, getAccessTokenSilently } = useAuth0();
    const [parent, setParent] = useState(null);
    const [validationIsValid, setValidationIsValid] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);
    
    const jerseySizes = [   
        {
            name: "XS",
            value: "XS",
            id: "XS"
        },
        {
            name: "S",
            value: "S",
            id: "S"
        },
        {
            name: "M",
            value: "M",
            id: "M"
        },
        {
            name: "L",
            value: "L",
            id: "L"
        },
        {
            name: "XL",
            value: "XL",
            id: "XL"
        },
    ];

    const isGift = true;

       
    useEffect(() => {
        const getParentData = async () => {
            const token = await getAccessTokenSilently();
            const parentData = await getContactById(token, user.memberId);
            const parentJacket = defaultJacketProps();
            parentJacket.firstName = parentData.data.firstName;
            parentJacket.lastName = parentData.data.lastName;
            setParent(parentJacket);
        }
        getParentData();
    }, [])
 
    useEffect(() => {
        parent &&
        setJacket([...jackets, parent]);
    }, [parent])

    useEffect(() => {
        let isValid = true; 
        jackets.forEach(j => {
            let isJacketValid = j.firstName && j.lastName && j.jerseyName && j.jerseyNumber && j.jerseySize 
            
            if (!isJacketValid){
                isValid = false
            }   
        });
        setValidationIsValid(isValid);        
    }, [jackets])

    const defaultJacketProps = () =>
    ({
        firstName: "",
        jerseyIndex: guidGenerator(),
        lastName: "",
        jerseySize: "",
        jerseyName: "",
        jerseyNumber: "",
        jerseyPosition: jackets.length,
        validation: false
    })

    let handleRemoveJersey = (jerseyInfo) => {
        setJacket(jackets.filter(jersey => jersey !== jerseyInfo));
    }

    function guidGenerator() {
        var S4 = function() {
           return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
        };
        return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
    }

    function handleJerseyInputChange(value, propName, jerseyIndex){
        let newJerseys = [...jackets];
        const jerseyToModify = newJerseys.filter(j => j.jerseyIndex === jerseyIndex)[0];
        jerseyToModify[propName] = value;
        if(value.length > 0){
            setValidationIsValid(true);
        }
        newJerseys = newJerseys.map(j => {
            if (j.jerseyIndex === jerseyToModify.jerseyIndex) {
                return jerseyToModify;
            }
            else {
                return j;
            }
        })
        setJacket(newJerseys);
    }  
   
    const handleSubmitClick = async (e) => {
        e.preventDefault();
        setSubmitting(true);
    
        if (!validationIsValid) {
            toast.error(`Form Submission Failed: Please Complete All Fields`);
        }
        
        else {
            const accessToken = await getAccessTokenSilently();
            let formSubmitted = await createGiftForm(accessToken, jackets);

            if (!formSubmitted.error) {
                toast.success(`Form Submitted!`);
            }
            else {
                toast.error(`Form Submission Failed: ${formSubmitted.error.message}`);
            }
        }
        setSubmitting(false);
    }

    const JacketComponents = jackets.map((j, i) => 
        <JerseyFormInputs 
            jerseyInfo={j} 
            handleInputValueChange={handleJerseyInputChange} 
            jerseyIndex={j.jerseyIndex} 
            key={j.jerseyIndex} 
            jerseySizesDropDown={jerseySizes}  
            handleRemoveJersey={handleRemoveJersey}
            isGift={isGift}
            
        />
    )

    return (
        <div className="admin-panel-outer">
            <div className="generic-title"> Jacket Request Form</div>
                <form id='jerseyForm' className="jerseyForm">
                    {JacketComponents}
                    <button className="btn btn1 submitJerseyButton" onClick={handleSubmitClick}>
                        {isSubmitting ? <Loader /> :
                        <><span>Submit</span> <i className="fa-regular fa-arrow-right"></i></>}
                    </button>
                </form>        
        </div>
    )
}