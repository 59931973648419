import React, { useState, useEffect } from "react";
import "../../styles/components/content.css"
import Loader from "../navigation/Loader";
import HelpfulLink from "./helpful-link";
import { checkForUserPermissions } from "../shared/utilities";




export default function HelpfulLinks({user}) {
    return (
        <div className="helpful-links">
            <div className="content-list-title">Helpful Links</div>
            <HelpfulLink 
                linkText="Members"
                linkUrl="/contacts/all"
                icon={<i className="fa-solid fa-people-simple"></i>}
            />
            <HelpfulLink 
                linkText="Profile"
                linkUrl="/profile"
                icon={<i className="fa-solid fa-person-dress-simple"></i>}
            />
            <HelpfulLink 
                linkText="Content"
                linkUrl="/content/all"
                icon={<i className="fa-solid fa-shapes"></i>}
            />
            {/* {checkForUserPermissions(["view:jerseyform", "site:admin", "IT:admin"], user) && 
            <HelpfulLink 
                linkText="Jersey Request"
                linkUrl="/jerseyform"
                icon={<i className="fa-solid fa-shirt"></i>}
            />} */}
            {checkForUserPermissions(["view:jerseyform", "site:admin", "IT:admin"], user) && 
            <HelpfulLink 
                linkText="Season Gift Form"
                linkUrl="/giftform"
                icon={<i className="fa-solid fa-gift"></i>}
            />}
        </div>
    )
}