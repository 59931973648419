import React, { useState, useEffect } from "react";
import ContentContactOption from "./content-contact-option";
import Loader from "../navigation/Loader";

export default function ContactCheckboxList({contacts, handleChangeCheckBox, loading, lablePhrase = "selected", showPhoneAndEmailAttrs = false, handleCheckAllChange }) {
    const [searchTerm, setSearchTerm] = useState("");
    const [checkAll, setCheckAll] = useState(false);
    
    // get count of contacts that are checked
    const activeCheckedCount = contacts == null ? 0 : contacts?.filter(c => c.checked).length;
    const searchMatch = (c) => searchTerm == "" || c.firstName.toLowerCase().includes(searchTerm.toLowerCase()) || c.lastName.toLowerCase().includes(searchTerm.toLowerCase());
    
    // make a useeffect hook that depends on contacts and resets the search term 
    useEffect(() => {
        setSearchTerm("");
    }, [contacts]);

    const handleSearchTermChange = (e) => {
        setSearchTerm(e.target.value);
    }

    const handleCheckAllChangeClick = (e) => {
        setCheckAll(e.target.checked);
        handleCheckAllChange(e.target.checked);
    } 

    const contactDivs = contacts?.map(c => 
        <ContentContactOption 
            contact={c} 
            key={c.id}                        
            handleChangeCheckBox={handleChangeCheckBox}
            showPhoneAndEmailAttrs={showPhoneAndEmailAttrs}
            searchMatch={searchMatch(c)}
        />
    )

    return (
        <div className="contacts-content-outer">
            <div>{activeCheckedCount} {lablePhrase}</div>
            { loading ? <Loader /> :                
                <>
                    <div className="content-contact-topbar">
                        <input onChange={handleCheckAllChangeClick} type="checkbox" checked={checkAll} />
                        <input className="content-contact-search" value={searchTerm} onChange={handleSearchTermChange} placeholder="search a user" type="text" />
                    </div>
                    <div className="content-contact-list">
                        {contactDivs}
                    </div>
                </>
            }
        </div>
    )
}