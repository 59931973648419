import { callExternalApi } from "./external-api.service";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const sendMessage = async (accessToken, formData) => {
  const config = {
    url: `${apiServerUrl}/api/messaging/send`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: formData
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
}