import { callExternalApi } from "./external-api.service";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const resetCache = async (accessToken) => {
  const config = {
    url: `${apiServerUrl}/api/admin/ResetCache`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: null
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
}