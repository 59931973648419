import React, { useState, useEffect } from "react";
import { PageLayout } from "../components/page-layout";
import GiftFormCreate from "../components/forms/form-gift-create";

export const GiftFormPage = () => {

    return (
        <PageLayout>
            {<GiftFormCreate/> }
        </PageLayout>
    )
} 