import { callExternalApi } from "./external-api.service";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;


export const createJerseyForm = async (accessToken, payload) => {
    const config = {
      url: `${apiServerUrl}/api/form/create`,
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: JSON.stringify(payload)
    };
  
    const { data, error } = await callExternalApi({ config });
  
    return {
      data: data || null,
      error,
    };
  }

  export const createGiftForm = async (accessToken, payload) => {
    const config = {
      url: `${apiServerUrl}/api/form/creategift`,
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: JSON.stringify(payload)
    };
  
    const { data, error } = await callExternalApi({ config });
  
    return {
      data: data || null,
      error,
    };
  }

  export const getAllJerseyForms = async (accessToken) => {
    const config = {
      url: `${apiServerUrl}/api/form/getall`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: {}
    };
    const { data, error } = await callExternalApi({ config });

    return {
      data: data || null,
      error,
    };   
  }

  export const getAllGiftForms = async (accessToken) => {
    const config = {
      url: `${apiServerUrl}/api/form/getallgifts`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: {}
    };
    const { data, error } = await callExternalApi({ config });

    return {
      data: data || null,
      error,
    };   
  }
