import React from "react";
import { LoginButton } from "../../components/buttons/login-button";

export const GuestHome = () => {
  return (
    <div className="guestHome">
      <div className="guestWelcomeText">
        <div>Welcome to Family Connect, home of D-backs Families!</div>
        <LoginButton className={"btn btn1"} />
      </div>
    </div>
  )
};