import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { NavBarTab } from "./nav-bar-tab";
import { isUserSiteAdmin } from "../../shared/utilities";

export const NavBarTabs = () => {
  const { isAuthenticated, user } = useAuth0();
  const isAdmin = isUserSiteAdmin(user);

  return (
    <div className="nav-bar__tabs">
      <NavBarTab path="/" label="Home" />
      {isAuthenticated && (
        <>
          <NavBarTab path="/profile" label="Profile" />
          <NavBarTab path="/contacts/all" label="Contacts" />
          <NavBarTab path="/content/all" label="Content" />
          {isAdmin &&
            <NavBarTab path="/admin" label="Admin" />
          }
        </>
      )}
    </div>
  );
};
