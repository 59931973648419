import React, { useEffect, useRef, useState } from "react";
import { searchContent } from "../../services/content-service";
import { useAuth0 } from "@auth0/auth0-react";
import { SearchResults } from "./searchResults";
import { isUserSiteAdmin } from "../shared/utilities";

export default function Search() {
    const [searchValue, updateSearch] = useState("");
    const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();
    const [searchResults, updateSearchResults] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [searchIsActive, setSearchIsActive] = useState(false);
    const inputRef = useRef(null);
    const isAdmin = isUserSiteAdmin(user);

    useEffect(() => {
        if (searchIsActive) {
            inputRef.current.focus();
        }
        else {
            inputRef.current.blur();
        }

    }, [searchIsActive])

    function handleSearchChange(e) {
        const searchTerm = e.target.value;
        updateSearch(searchTerm);

        if (searchTerm.length > 2) {
            triggerSearch(searchTerm);
        }
        else if (searchTerm.length == 0) {
            updateSearchResults({});
        }
    }

    function handleSearchKeyUp(e) {
        if (e.key === "Escape") {
            updateSearch("");
            updateSearchResults({});
            setSearchIsActive(false);
        }
    }

    const handleBGClick = (e) => setSearchIsActive(false);

    const handleInputClick = (e) => setSearchIsActive(true);

    const handleMagGlassClick = (e) => {
        setSearchIsActive(true);
        inputRef.current.focus();
    } 

    const triggerSearch = async (term) => {
        isAuthenticated && 
            setIsLoading(true);
            const accessToken = await getAccessTokenSilently();
            const searchResultSet = await searchContent(accessToken, term);
            setIsLoading(false);
            updateSearchResults(searchResultSet.data);        
    }

    return (
        <div className="searchResultsAnchor">
            <i onClick={handleMagGlassClick} className={`fa-solid fa-magnifying-glass searchIcon ${searchIsActive ? 'active' : ''}`}></i>
            <input ref={inputRef} onFocus={handleInputClick} placeholder="search contacts and content" className={`siteSearchInput ${searchIsActive ? 'active' : ''}`} onKeyUp={handleSearchKeyUp} onChange={handleSearchChange} value={searchValue} type="text" />
            <SearchResults isAdmin={isAdmin} isLoading={isLoading} searchIsActive={searchIsActive} resultList={searchResults} user={user}/>
            <div onClick={handleBGClick} className={`searchResultsBG ${searchIsActive ? 'active' : ''}`}></div>
        </div>
    )
}