import React, { useState, useCallback } from "react";
import Cropper from 'react-easy-crop';
import "../../styles/components/cropper.css";
import { getCroppedImg, getRotatedImage } from './canvas-utils'

export const ProfilePicUpdater = ({isActive, handleExitCropperclick, handleSubmitClick, src}) => {
  const [imageSrc, setImageSrc] = useState(src)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [isLoadingMedia, setMediaLoading] = useState(false);

  const ORIENTATION_TO_ANGLE = {
    '3': 180,
    '6': 90,
    '8': -90,
  }

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setMediaLoading(true);
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);

      setImageSrc(imageDataUrl);
      setMediaLoading(false);
    }
  }

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels)
    }, [])

  const processCroppedImage = useCallback(async () => {
    try {
      const croppedImageData = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation
      )
      croppedImageData.blob = await croppedImageData.blob;
      handleSubmitClick(croppedImageData);
    } catch (e) {
      console.error(e)
    }
  }, [imageSrc, croppedAreaPixels, rotation])

  const handleZoomChange = (e) => {
    setZoom(e.target.value);
  }

  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }

  return (
    <div className={`cropper-container ${isActive ? "active" : ""}`}>
        <div className="cropper-container-inner">
          <input type="file" onChange={onFileChange} accept="image/*" />
            <div className={`cropper-image-wrapper ${isLoadingMedia ? 'hidden' : ''}`}>
                <Cropper
                    image={imageSrc}
                    crop={crop}
                    rotation={rotation}
                    zoom={zoom}
                    aspect={1 / 1}
                    onCropChange={setCrop}
                    onRotationChange={setRotation}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                />
            </div>
            <input type="range" min="1" max="3" step={.1} onChange={handleZoomChange} value={zoom} className="slider" />
            {/* <div className="cropper-output">
              <img src={croppedImage} />
            </div> */}
            <div style={{display: "flex", gridGap: "15px", marginTop: "18px"}}>
                <button className="btn btn2" onClick={handleExitCropperclick}>Cancel</button>
                <button className="btn btn1" onClick={processCroppedImage}>Done</button>
            </div>
        </div>        
    </div>
    
  )
}