import React, { useState, useEffect } from "react";
import Contact from "../components/contacts/contact";
import { PageLayout } from "../components/page-layout";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { deleteContactById, getContactById } from "../services/contact-service";
import { isUserContentAdmin, isUserSiteAdmin } from "../components/shared/utilities";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

export const ContactPage = () => {
    let { id } = useParams();
    const [contact, setContact] = useState(null);
    const { getAccessTokenSilently, user } = useAuth0();

    const isUserAdmin = isUserSiteAdmin(user);
    const isUserViewingOwnProfile = user.memberId == id;
    const navigate = useNavigate();

    useEffect(() => {
        const retrieveContact = async () => {
            const token = await getAccessTokenSilently();
            const result = await getContactById(token, id);
            setContact(result.data);
        }
        retrieveContact();
    }, [])

    const handleDeleteClick = async (e) => {
        var token = await getAccessTokenSilently();
        var deleted = await deleteContactById(token, contact.id);
        if (!deleted.error) {
            navigate("/contacts/all");
        }
        else {
            toast.error(`There was an issue deleting the contact: ${deleted.error.message}}`);
        }
    }

    return (
        <PageLayout>
            <Contact viewerIsAdmin={isUserAdmin} contact={contact} viewingOwnProfile={isUserViewingOwnProfile} />
        </PageLayout>
    )
} 