import React, { useState, useEffect } from "react";
import { getAllContacts } from "../../services/contact-service";
import { useAuth0 } from "@auth0/auth0-react";
import "../../styles/components/contact.css"
import Contact from "./contact";
import Loader from "../navigation/Loader";
import { isUserSiteAdmin } from "../shared/utilities";

export default function ContactList({id, showFullVersion = true}) {
    const [contacts, setContacts] = useState([]);
    const [loading, setLoading] = useState(true);
    const { getAccessTokenSilently, user } = useAuth0();
    const viewerIsAdmin = isUserSiteAdmin(user);

    useEffect(() => {
        const retrieveContacts = async () => {
            setLoading(true);
            const token = await getAccessTokenSilently();
            const result = await getAllContacts(token);            
            setContacts(result.data.filter(r => !r.hideContactFromOthers));
            setLoading(false);
        }
        retrieveContacts();
    }, [])

    const contactDivs =contacts?.sort((a,b) => a.firstName.localeCompare(b.firstName)).map(c => 
        <Contact viewerIsAdmin={viewerIsAdmin} contact={c} key={c.id} showFullVersion={false} />
    )

    return (
        <div className="contacts-outer">
            <div className="generic-title">D-backs Family Members & Contacts</div>
            { loading ? <Loader /> :
                <div className="contact-list">
                    {contactDivs}
                </div>
            }
        </div>
    )
}