import React from "react";
import "../../styles/admin.css";

export const AdminButton = ({icon, buttonText, clickHandler}) => { 
  return (
    <div className="admin-component">
        {icon}
        <button onClick={clickHandler} className="admin-btn">
            {buttonText}
            <i style={{color: "white"}} className="fa-light fa-arrow-right"></i>
        </button>
    </div>
  );
};