import React, { useState, useEffect } from "react";
import "../../styles/components/content.css"
import Loader from "../navigation/Loader";

export default function HelpfulLink({linkText, linkUrl, icon}) {
    return (
        <a href={linkUrl} className="helpful-link">
            {icon}
            <div>{linkText}</div>
        </a>
    )
}