import Loader from "../navigation/Loader";

export const AreYouSureModal = ({onCompleteAction, handleCancelAction, loadingState, isVisible}) => {
    if(!isVisible) {
        return null;
    }

    return(
            <div className="modal">
                <div className="modal-content">
                    <div className="modal-title">
                        <div>Are you sure?</div>
                    </div>
                    <div className="modal-body">To proceed with the deletion, click yes. To cancel the deletion, click cancel.</div>
                    <div className="modal-footer">
                        <button className="button btnCancel" onClick={handleCancelAction}>Cancel</button>
                        <button className="button btn1" onClick={onCompleteAction}>
                            {loadingState ? <Loader /> : 'Yes'}
                        </button>
                    </div>
                </div>
            </div>
        )
}