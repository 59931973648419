import React from "react";
import toast, { Toaster } from "react-hot-toast";
import { NavBar } from "./navigation/desktop/nav-bar";
import { MobileNavBar } from "./navigation/mobile/mobile-nav-bar";
import { PageFooter } from "./page-footer";

export const PageLayout = ({ children, fullWidth = false }) => {
  return (
    <div className='page-layout'>
      <NavBar />
      <MobileNavBar />
      <Toaster 
        toastOptions={{
          style: {
            fontSize: '18px',
            fontWeight: '400',
            color: 'black',
          },
        }}
      />
      <div className={`page-layout__content ${fullWidth ? 'layout-fullwidth' : ''}`}>
        {children}
      </div>
      <PageFooter />
    </div>
  );
};
