import React from "react";

export const PageFooter = () => {

  return (
    <footer>
        <div className="navbar-footer">
            <a asp-action="TermsOfService" asp-controller="Home" className="navbar-footer-btn">Terms of Service</a>
            <a href="https://www.mlb.com/official-information/privacy-policy" className="navbar-footer-btn">Privacy Policy</a>
            <a asp-action="" asp-controller="Home" className="navbar-footer-btn">Home</a>
            <a className="navbar-footer-btn" href="mailto:dbacksfoundation@dbacks.com">Contact Us</a>
            <div className="footer-copyright">© {new Date().getFullYear()} AZPB, LP & MLB Advanced Media, LP. All rights reserved.</div>
        </div>
    </footer>
  );
};
