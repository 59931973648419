import { callExternalApi } from "./external-api.service";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const uploadContent = async (accessToken, formData, id = null) => {
    const config = {
      url: id ? `${apiServerUrl}/api/content/update/${id}` : `${apiServerUrl}/api/content/new`,
      method: id ? "PUT" : "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: formData
    };
  
    const { data, error } = await callExternalApi({ config });
  
    return {
      data: data || null,
      error,
    };
  }

  export const getContent = async (accessToken, payload) => {
    const config = {
      url: `${apiServerUrl}/api/content/getall`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: payload
    };
  
    const { data, error } = await callExternalApi({ config });
  
    return {
      data: data || null,
      error,
    };
  }

  export const getContentById = async (accessToken, id) => {
    const config = {
      url: `${apiServerUrl}/api/content/${id}`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: {}
    };
  
    const { data, error } = await callExternalApi({ config });
  
    return {
      data: data || null,
      error,
    };
  }

  export const searchContent = async (accessToken, searchTerm) => {
    const config = {
      url: `${apiServerUrl}/api/home/search?searchText=${encodeURIComponent(searchTerm)}`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: null
    };
  
    const { data, error } = await callExternalApi({ config });
  
    return {
      data: data || null,
      error,
    };
  }

  export const updateSortOrder = async (accessToken, payload) => {
    const config = {
      url: `${apiServerUrl}/api/content/updateSortOrder`,
      method: "PUT",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: JSON.stringify(payload)
    };
  
    const { data, error } = await callExternalApi({ config });
  
    return {
      data: data || null,
      error,
    };
  } 

  export const deleteContentById = async (accessToken, id) => {
  const config = {
    url: `${apiServerUrl}/api/content/${id}`,
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: {}
  }; 

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
}