import React, { useState } from "react";
import { Dropdown } from "../shared/dropdown";
import "../../styles/general.css";
import "../../styles/components/forms.css";

export default function JerseyFormInputs({jerseyInfo = null, handleInputValueChange, jerseyIndex, handleRemoveJersey = null, isGift = false}) {
    
    return (
                    
        <div className="jersey-input-section">  
            { jerseyInfo.jerseyPosition > 0 && <div  className="jerseyFormX"><span className="tooltiptext">Remove Jersey</span><i onClick={(e) => handleRemoveJersey(jerseyInfo)} className="fa-solid fa-trash"></i></div>}
            
            {jerseyInfo.jerseyPosition > 0 ? (<div className="jerseyTitle"> Kid Jersey #{jerseyInfo.jerseyPosition}</div>) : 
            <>{isGift ? (<div className="jerseyTitle">Adult Women's Satin Bomber Jacket</div> ) :(<div className="jerseyTitle">Adult Jersey</div> ) } </>
            }
            
                <div className="form-fieldgroup">        
                    <label className="jersey-input-label"> First Name</label>       
                    <input
                        className="firstName"
                        type="text"
                        value={jerseyInfo.firstName}
                        onChange={(e) => handleInputValueChange(e.target.value, "firstName", jerseyIndex)}
                    />
                </div>  
                <div className="form-fieldgroup">
                    <label className="jersey-input-label">Last Name</label>
                    <input
                        className="lastName"
                        type="text"
                        value={jerseyInfo.lastName}
                        onChange={(e) => handleInputValueChange(e.target.value, "lastName", jerseyIndex)}
                    />
                </div>
                
                <div className="form-fieldgroup">
                    {isGift ? (<label className="jersey-input-label">Satin Bomber Jacket Size</label>) :
                    (<label className="jersey-input-label">Jersey Size (Please be specific)</label>)}
                    <input
                        className="jerseySize"
                        type="text"
                        value={jerseyInfo.jerseySize}
                        onChange={(e) => handleInputValueChange(e.target.value, "jerseySize", jerseyIndex)}
                    />
                </div>
                <div className="form-fieldgroup">
                    {isGift ? (<label className="jersey-input-label">Name on Jacket</label>):
                    (<label className="jersey-input-label">Jersey Name</label>)}
                    <input
                        className="jerseyName"
                        type="text"
                        value={jerseyInfo.jerseyName}
                        onChange={(e) => handleInputValueChange(e.target.value, "jerseyName", jerseyIndex)}
                    />
                </div>
                <div className="form-fieldgroup">
                {isGift ? (<label className="jersey-input-label">Number on Jacket</label>):
                    (<label className="jersey-input-label">Jersey Number</label>)}
                    <input
                        className="jerseyNumber"
                        type="number"
                        value={jerseyInfo.jerseyNumber}
                        onChange={(e) => handleInputValueChange(e.target.value, "jerseyNumber", jerseyIndex)}
                    />
                </div>
                
            
        </div>                
            
           
        
    )
}