export const resetProperties = {
    showAreYouSureDeleteModal: false,
    cropperIsActive: false,
    dbacksPlayers: [],
    selectedPlayer: {name: "", id: "", playerCoach: ""},
    permissions: null,
    submitting: false,
    photoData: {
        profileImageUrlExisting : null,
        profileImageDataTemp: {
            base64: null,
            blob: null
        }
    },
    formFields: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        socials: 
        {
            twitter: "",
            insta: "",
            tikTok: ""
        },
        relatedPlayer: null,
        relationToPlayer: "",
        isSiteUser: false,
        isConnectedToPlayer: false,
        alternateDescription: "",
        permissions: null,
        newProfileImgBase64: null,
        profileImageURL: null,
        hideContactFromOthers: false,
    }            
}