import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { PageLoader } from "./components/page-loader";
import { AuthenticationGuard } from "./components/authentication-guard";
import { CallbackPage } from "./pages/callback-page";
import { HomePage } from "./pages/home-page";
import { NotFoundPage } from "./pages/not-found-page";
import { ProfilePage } from "./pages/profile-page";
import { AdminPage } from "./pages/admin-page";
import { NewContentPage } from "./pages/new-content-page";
import { NewContactPage } from "./pages/new-contact-page";
import { ContactPage } from "./pages/contact-page";
import { AllContentPage } from "./pages/all-content-page";
import { AllContactsPage } from "./pages/all-contacts.page";
import { LoginPage } from "./pages/login-page";
import { ContentEditPage } from "./pages/content-edit-page";
import { ContactEditPage } from "./pages/contact-edit-page";
import { JerseyFormPage } from "./pages/jersey-form-page";
import { GiftFormPage } from "./pages/gift-form-page";
import { GiftAdminPage } from "./pages/gifts-admin-page";
import { JerseyAdminPage } from "./pages/jersey-admin-page";
import { SendMessagePage } from "./pages/send-message-page";

export const App = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route
        path="/profile"
        element={<AuthenticationGuard component={ProfilePage} />}
      />
      <Route
        path="/admin"
        element={<AuthenticationGuard component={AdminPage} />}
      />
      <Route
        path="/content/all"
        element={<AuthenticationGuard component={AllContentPage} />}
      />
      <Route
        path="/contacts/all"
        element={<AuthenticationGuard component={AllContactsPage} />}
      />
      <Route
        path="/content/new"
        element={<AuthenticationGuard component={NewContentPage} />}
      />
      <Route
        path="/content/edit/:id"
        element={<AuthenticationGuard component={ContentEditPage} />}
      />
      <Route
        path="/contacts/edit/:id"
        element={<AuthenticationGuard component={ContactEditPage} />}
      />
      <Route
        path="/contacts/new"
        element={<AuthenticationGuard component={NewContactPage} />}
      />
      <Route
        path="/contacts/:id"
        element={<AuthenticationGuard component={ContactPage} />}
      />
      <Route
        path="/jerseyform"
        element={<AuthenticationGuard component={JerseyFormPage} />}
      />
      <Route
        path="/giftform"
        element={<AuthenticationGuard component={GiftFormPage} />}
      />
      <Route
        path="/jerseyadmin"
        element={<AuthenticationGuard component={JerseyAdminPage} />}
      />
      <Route
        path="/giftadmin"
        element={<AuthenticationGuard component={GiftAdminPage} />}
      />
      <Route
        path="/message/send"
        element={<AuthenticationGuard component={SendMessagePage} />}
      />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/callback" element={<CallbackPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
