import React, { useState, useEffect } from "react";

export const Dropdown = ({className, id, label, value, dropdownValues, handleDropdownChange, defaultPhrase = 'Select One'}) => {
    const dropdownOptions = dropdownValues.map(d => 
        <option data-customvalue1={d.customvalue1} key={d.id} value={d.id}>{d.name}</option>
    )

    return (
        <div className={className && className}>
            {label && 
                <label htmlFor={id}>{label}</label>
            }
            <select id={id} value={value ?? "-1"} onChange={handleDropdownChange}>
                <option value="-1">{defaultPhrase}</option>
                {dropdownOptions}
            </select>
        </div>
    )
}