import React from "react";
import { NavLink } from "react-router-dom";

export const MobileNavBarBrand = ({ handleClick }) => {
  return (
    <div onClick={handleClick} className="mobile-nav-bar__brand">
      <NavLink to="/">
        <img
          className="mobile-nav-bar__logo"
          // src="https://azdfmlyappsa.blob.core.windows.net/images/dbacks-a-logo.png"
          src="https://azdfmlyappsa.blob.core.windows.net/images/fmlyLogo.png"
          alt="D-backs logo"
          width="60"
          height="60"
        />
      </NavLink>
    </div>
  );
};
