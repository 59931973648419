import React, { useState, useEffect } from "react";
import ContactCheckboxList from "../../components/content/contact-checkbox-list";
import { getAllContacts } from "../../services/contact-service";
import { useAuth0 } from "@auth0/auth0-react";
import "../../styles/components/messaging.css"
import { Dropdown } from "../shared/dropdown";
import { sendMessage } from "../../services/message-service";
import toast from "react-hot-toast";

export const MessageSender = () => {
    const dropdownVals = [
        {"id": "Email", "name": "Email", "customvalue1": "email"},
        {"id": "SMS", "name": "SMS", "customvalue1": "phone"},
        {"id": "Both", "name": "Both", "customvalue1": "both"}
    ]
    const [contacts, setContacts] = useState([]);
    const [contactsLoading, setContactsLoading] = useState(true);
    const [messageType, setMessageType] = useState(null);
    const [message, setMessage] = useState("");
    const { getAccessTokenSilently, user } = useAuth0();
    
    useEffect(() => {
        const retrieveContacts = async () => {
            setContactsLoading(true);
            const token = await getAccessTokenSilently();
            const result = await getAllContacts(token);
            setContacts(result.data.map(c => 
                ({...c, checked: false})
            ));
            setContactsLoading(false);
        }
        retrieveContacts();
    }, []);

    const checkAllChangeHandler = (allChecked) => {
        const newContacts = [...contacts];
        setContacts(newContacts.map(c => ({...c, checked : allChecked})));
    }

    const handleChangeCheckBox = (e) => {
        const contactId = e.target.dataset.contactid;
        const newContacts = [...contacts];
        const contactIndex = newContacts.findIndex(c => c.id === contactId);
        newContacts[contactIndex].checked = !newContacts[contactIndex].checked;
        setContacts(newContacts);
    }

    const handleSetMessageType = (e) => {
        setMessageType(e.target.value);
    }

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
    }

    const resetState = () => {
        setMessage("");
        setMessageType(null);
        const newContacts = [...contacts];
        setContacts(newContacts.map(c => ({...c, checked: false})));
    }

    async function SubmitMessage() {
        const submissionObj = {
            "MessageTypes": messageType,
            "Recipients": contacts.filter(c => c.checked).map(c => {
                return {
                    "Email": c.email,
                    "PhoneNumber": c.phone
                }
            }),
            "Message": message
        }

        //if recipients is empty or message is empty or message type is empty, throw toast error
        if (submissionObj.Recipients.length === 0 || submissionObj.Message === "" || submissionObj.MessageTypes === null) {
            toast.error("please fill out all fields");
            return;
        }

        const token = await getAccessTokenSilently();
        const result = await sendMessage(token, submissionObj);

        if (result.error) {
            console.error(result.error);
            toast.error("error sending message");
        }
        else {
            toast.success("message sent!");
            resetState();
        }
    }

    return (
        <div className="msg-component-outer">
            <div className="msg-contact-list">
                <div className="msg-section-label">1. Select Recipients</div>
                <ContactCheckboxList 
                    contacts={contacts}
                    loading={contactsLoading}
                    handleChangeCheckBox={handleChangeCheckBox}
                    showPhoneAndEmailAttrs={true}
                    handleCheckAllChange={checkAllChangeHandler}
                />
            </div>
            <div className="msg-input-outer">
                <div className="msg-section-label">2. Write your message!</div>
                <textarea value={message} onChange={handleMessageChange} className="msg-input" placeholder="hello"></textarea>
                <div className="msg-bottom-row">
                    <Dropdown 
                        className="msg-type-dd" 
                        dropdownValues={dropdownVals} 
                        label="Send using:"
                        value={messageType}
                        handleDropdownChange={handleSetMessageType}
                    />
                    <button onClick={SubmitMessage} className="btn btn1">Send <i className="fa-solid fa-arrow-right"></i></button>
                </div>
            </div>    
        </div>            
    );
}