import React from "react";
import "../../styles/components/content.css"

export default function ContentItem({content, backgrounds, canEdit = false}) {
    const backgroundImage = backgrounds == null ? "" : backgrounds[Math.floor(Math.random()*backgrounds.length)];

    return ( 
        <a target="_blank" href={content.blobUrlAccessible} className="content-tile" key={content.id}>
            <div className="content-tile-bg" style={{backgroundImage: `url('${backgroundImage}')`}}></div>
            <div className="content-tile-left">
                <div className="content-tile-title">{content.description}</div>
            </div>
            <div className="content-tile-view" ><i className="fa-light fa-arrow-right"></i></div>
            {canEdit &&
                <div className="content-edit-btn">
                    <a href={`/content/edit/${content.id}`} className="btn">
                        Edit
                    </a>
                </div>
            }
        </a>
        ) 
}