import React, { useState, useEffect } from "react";
import { PageLayout } from "../components/page-layout";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { getContactById } from "../services/contact-service";
import { CreateEditContact } from "../components/contacts/create-edit-contact";
import { isUserSiteAdmin } from "../components/shared/utilities";


export const ContactEditPage = () => {
    let { id } = useParams();
    const [contact, setContact] = useState(null);

    const { getAccessTokenSilently, user } = useAuth0();
    const isContentAdmin = isUserSiteAdmin(user);

    useEffect(() => {
        const retrieveContact = async () => {
            const token = await getAccessTokenSilently();
            const result = await getContactById(token, id);
            setContact(result.data);
        }
        retrieveContact();
    }, [])

    return (
        <PageLayout>
            {contact && <CreateEditContact contact={contact} currentUserAdmin={isContentAdmin} /> }
        </PageLayout>
    )
} 