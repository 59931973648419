import React, { useState, useEffect } from "react";
import { PageLayout } from "../components/page-layout";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { getContentById } from "../services/content-service";
import ContentCreateEdit from "../components/content/content-create-edit";

export const ContentEditPage = () => {
    let { id } = useParams();
    const [content, setContent] = useState(null);
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        const retrieveContent = async () => {
            const token = await getAccessTokenSilently();
            const result = await getContentById(token, id);
            setContent(result.data);
        }
        retrieveContent();
    }, [])

    return (
        <PageLayout>
            {content && <ContentCreateEdit content={content} /> }
        </PageLayout>
    )
} 