import React from "react";
import { PageLayout } from "../components/page-layout";
import ContentCreateEdit from "../components/content/content-create-edit";

import ContentList from "../components/content/content-list";

export const NewContentPage = () => (
  <PageLayout>
    <ContentCreateEdit />
    
  </PageLayout>
);
