import React, { useState, useEffect, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import "../../styles/components/content.css"
import "../../styles/general.css";
import "../../styles/components/forms.css";
import {Table} from '../shared/table.js'
import { getAllJerseyForms } from "../../services/form-service";
import { CSVLink } from "react-csv";




export default function JerseyFormAdmin({id, showFullVersion = true}) {
  const [jerseys, setJerseys] = useState([]);
  const [loading, setLoading] = useState(true);
  const { getAccessTokenSilently, user } = useAuth0();
  

useEffect(() => {
  const retrieveJerseys = async () => {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const result = await getAllJerseyForms(token);            
      setJerseys(result.data);
      setLoading(false);
  }
  retrieveJerseys();
}, [])

const columns = React.useMemo(
  () => [
    {
      Header: 'Jersey Info',
      columns: [
        {
          Header: 'First Name',
          accessor: 'firstName',
        },
        {
          Header: 'Last Name',
          accessor: 'lastName',
        },
        {
          Header: 'Jersey Name',
          accessor: 'jerseyName',
        },
        {
          Header: 'Jersey Number',
          accessor: 'jerseyNumber',
          Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
        },
        {
          Header: 'JerseySize',
          accessor: 'jerseySize',
        }
      ],
    },
    
  ],
  []
)


  return (
    
    <>
      <div className="generic-title"> Jersey Request Admin</div>
      <CSVLink data={jerseys} className="btn btn1 tableCSVBtn" filename={"jersey-report.csv"}>Download CSV</CSVLink>
      <Table columns={columns} data={jerseys} />
      </>
   
  )


}
