import React, { useState, useEffect } from "react";
import { PageLayout } from "../components/page-layout";
import JerseyFormCreate from "../components/forms/form-jersey-create";

export const JerseyFormPage = () => {

    return (
        <PageLayout>
            {<JerseyFormCreate/> }
        </PageLayout>
    )
} 