import React, { useState, useEffect, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import "../../styles/components/content.css"
import Loader from "../navigation/Loader";
import  JerseyFormInputs from "./form-jersey-person-inputs";
import { getContactById } from "../../services/contact-service";
import { createJerseyForm } from "../../services/form-service";
import toast from 'react-hot-toast';
import "../../styles/general.css";
import "../../styles/components/forms.css";

export default function JerseyFormCreate() {
    const [jerseys, setJersey] = useState([]);
    const { user, getAccessTokenSilently } = useAuth0();
    const [parent, setParent] = useState(null);
    const [validationIsValid, setValidationIsValid] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);
    
    const jerseySizes = [   
        {
            name: "XS",
            value: "XS",
            id: "XS"
        },
        {
            name: "S",
            value: "S",
            id: "S"
        },
        {
            name: "M",
            value: "M",
            id: "M"
        },
        {
            name: "L",
            value: "L",
            id: "L"
        },
        {
            name: "XL",
            value: "XL",
            id: "XL"
        },
    ];
       
    useEffect(() => {
        const getParentData = async () => {
            const token = await getAccessTokenSilently();
            const parentData = await getContactById(token, user.memberId);
            const parentJersey = defaultJerseyProps();
            parentJersey.firstName = parentData.data.firstName;
            parentJersey.lastName = parentData.data.lastName;
            setParent(parentJersey);
        }
        getParentData();
    }, [])
 
    useEffect(() => {
        parent &&
        setJersey([...jerseys, parent]);
    }, [parent])

    useEffect(() => {
        let isValid = true; 
        jerseys.forEach(j => {
            let isJerseyValid = j.firstName && j.lastName && j.jerseyName && j.jerseyNumber && j.jerseySize 
            
            if (!isJerseyValid){
                isValid = false
            }   
        });
        setValidationIsValid(isValid);        
    }, [jerseys])

    const defaultJerseyProps = () =>
    ({
        firstName: "",
        jerseyIndex: guidGenerator(),
        lastName: "",
        jerseySize: "",
        jerseyName: "",
        jerseyNumber: "",
        jerseyPosition: jerseys.length,
        validation: false
    })

    let handleAddJersey = (e) => {
        e.preventDefault();
        let jerseyInfo =  defaultJerseyProps();
        setJersey([...jerseys, jerseyInfo]);
    }

    let handleRemoveJersey = (jerseyInfo) => {
        setJersey(jerseys.filter(jersey => jersey !== jerseyInfo));
    }

    function guidGenerator() {
        var S4 = function() {
           return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
        };
        return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
    }

    function handleJerseyInputChange(value, propName, jerseyIndex){
        let newJerseys = [...jerseys];
        const jerseyToModify = newJerseys.filter(j => j.jerseyIndex === jerseyIndex)[0];
        jerseyToModify[propName] = value;
        if(value.length > 0){
            setValidationIsValid(true);
        }
        newJerseys = newJerseys.map(j => {
            if (j.jerseyIndex === jerseyToModify.jerseyIndex) {
                return jerseyToModify;
            }
            else {
                return j;
            }
        })
        setJersey(newJerseys);
    }  
   
    const handleSubmitClick = async (e) => {
        e.preventDefault();
        setSubmitting(true);
    
        if (!validationIsValid) {
            toast.error(`Form Submission Failed: Please Complete All Fields`);
        }
        
        else {
            const accessToken = await getAccessTokenSilently();
            let formSubmitted = await createJerseyForm(accessToken, jerseys);

            if (!formSubmitted.error) {
                toast.success(`Form Submitted!`);
            }
            else {
                toast.error(`Form Submission Failed: ${formSubmitted.error.message}`);
            }
        }
        setSubmitting(false);
    }

    const JerseyComponents = jerseys.map((j, i) => 
        <JerseyFormInputs 
            jerseyInfo={j} 
            handleInputValueChange={handleJerseyInputChange} 
            jerseyIndex={j.jerseyIndex} 
            key={j.jerseyIndex} 
            jerseySizesDropDown={jerseySizes}  
            handleRemoveJersey={handleRemoveJersey}
            
        />
    )

    return (
        <div className="admin-panel-outer">
            <div className="generic-title"> Jersey Request Form</div>
                <form id='jerseyForm' className="jerseyForm">
                    {JerseyComponents}
                    <button className="btn btn2 addJerseyButton" onClick={handleAddJersey}>Add Jersey <i className="fa-regular fa-plus"></i></button>
                    <button className="btn btn1 submitJerseyButton" onClick={handleSubmitClick}>
                        {isSubmitting ? <Loader /> :
                        <><span>Submit</span> <i className="fa-regular fa-arrow-right"></i></>}
                    </button>
                </form>        
        </div>
    )
}