import React from "react";

export const ProfilePic = ({src, placeholderInitials, handleEditClick, editable=true}) => {
    return (
        <div className="contact-profile-container">
            {
                src == null ? <div className="contact-profile-pic-placeholder">{placeholderInitials}</div>
                    : <img className="contact-profile-pic-img" src={src} />
            }                
            { editable && <div onClick={handleEditClick} className="contact-profile-edit-btn">Edit</div> }
        </div>
    )
}