import React from "react";
import { NavLink } from "react-router-dom";

export const NavBarBrand = () => {
  return (
    <div className="nav-bar__brand">
      <NavLink className="navbar-brand-link" to="/">
        <img
          className="nav-bar__logo"
          //src="https://azdfmlyappsa.blob.core.windows.net/images/dbacks-a-logo.png"
          src="https://azdfmlyappsa.blob.core.windows.net/images/fmlyLogo.png"
          alt="D-backs logo"
        />
        {/* <div className="navbar-brand-name">D-backs Families</div> */}
      </NavLink>
    </div>
  );
};
