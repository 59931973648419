import React from "react";
import { PageLayout } from "../components/page-layout";
import { CreateEditContact } from "../components/contacts/create-edit-contact";

export const NewContactPage = () => {
    return (
        <PageLayout>
            <CreateEditContact />
        </PageLayout>
    )
} 