import React, { useState, useEffect } from "react";
import { getDbacksPlayers, createUpdateContact, deleteContactById } from "../../services/contact-service";
import { useAuth0 } from "@auth0/auth0-react";
import { Dropdown } from "../shared/dropdown";
import { CreateContactField } from "./create-contact-field";
import Loader from "../navigation/Loader";
import toast from 'react-hot-toast';
import { ProfilePicUpdater } from "./profile-pic-updater";
import { ProfilePic } from "./profile-pic";
import { AreYouSureModal } from "../shared/are-you-sure";
import "../../styles/general.css";
import { convertPermissionsForSubmit, convertPermissionsForView, getAvailablePermissions } from "../shared/permissions";
import Select from 'react-select';
import { resetProperties } from "../shared/contact-constants";

export const CreateEditContact = ({contact = null, currentUserAdmin = true, currentUserViewingOwnProfile = false}) => {
    const editMode = contact != null;
    const [showModal, setShowModal] = useState(initialStateHandler("showAreYouSureDeleteModal"));
    const [dbacksPlayers, setDbacksPlayers] = useState(initialStateHandler("dbacksPlayers"));
    const [selectedPlayer, setSelectedPlayer] = useState(initialStateHandler("selectedPlayer"));
    const [permissions, setPermissions] = useState(initialStateHandler("permissions"));
    const [isSubmitting, setSubmitting] = useState(initialStateHandler("submitting"));
    const [isDeleting, setDeleting] = useState(initialStateHandler("deleting"));
    const [formFields, setFormFields] = useState(initialStateHandler("formFields"));
    const [cropperIsActive, setCropperIsActive] = useState(initialStateHandler("cropperIsActive"));
    const { getAccessTokenSilently } = useAuth0();   
    const [profilePic, setProfilePic] = useState(initialStateHandler("photoData"));
    const contactInitials = (formFields.firstName == null || formFields.firstName == "" ? "AA" : formFields.firstName[0]) + (formFields.lastName == null || formFields.lastName == "" ? "" : formFields.lastName[0]);

    function initialStateHandler(propName) {
        // if in edit mode, set initial values to contacts stuff
        if (contact) {
            const contactEditInitialValues = 
            {
                showAreYouSureDeleteModal: false,
                cropperIsActive: false,
                dbacksPlayers: [],
                permissions: convertPermissionsForView(contact.permissions),
                selectedPlayer: {
                    name: contact.relatedPlayer?.name ?? "", 
                    id: contact.relatedPlayer?.id ?? "",
                    playerCoach: contact.relatedPlayer?.playerCoach ?? ""
                },
                submitting: false,
                photoData: {
                    profileImageUrlExisting : null,
                    profileImageDataTemp: {
                        base64: null,
                        blob: null
                    }
                },
                formFields: {
                    firstName: contact.firstName,
                    lastName: contact.lastName,
                    email: contact.email,
                    phone: contact.phone,
                    socials:
                    {
                        twitter:  contact.socials?.twitter,
                        insta: contact.socials?.insta,
                        tikTok:  contact.socials?.tikTok 
                    },
                    relatedPlayer: contact.relatedPlayer,
                    relationToPlayer: contact.relationToPlayer,
                    isSiteUser: contact.isSiteUser,
                    isConnectedToPlayer: contact.relatedPlayer != null,
                    alternateDescription: contact.alternateDescription,
                    permissions: contact.permissions,
                    newProfileImgBase64: null,
                    profileImageURL: contact.profileImageURL,
                    hideContactFromOthers: contact.hideContactFromOthers
                }
            } 

            return contactEditInitialValues[propName];
        }
        // otherwise set to reset vals
        else {
            return resetProperties[propName];
        }
    }

    function resetStateDefaults(propName) {
        return resetProperties[propName];
    }

    const resetState = () => {
        setDbacksPlayers(resetStateDefaults("dbacksPlayers"));
        setSelectedPlayer(resetStateDefaults("selectedPlayer"));
        setSubmitting(resetStateDefaults("submitting"));
        setDeleting(resetStateDefaults("deleting"));
        setCropperIsActive(resetStateDefaults("cropperIsActive"));
        setFormFields(resetStateDefaults("formFields"));
        setProfilePic(resetStateDefaults("photoData"));
        setShowModal(resetStateDefaults("showing"));
        setPermissions(resetStateDefaults("permissions"));
    }

    // populate player dropdown
    useEffect(() => {
        const getPlayersForDropdown = async () => {
            const accessToken = await getAccessTokenSilently();
            let players = await getDbacksPlayers(accessToken);

            setDbacksPlayers(
                players.data.roster.map(r => ({
                    id : r.person.id,
                    name : r.person.fullName,
                    customvalue1 : r.position == null ? "coach" : "player"
                }))
            );
        }
        getPlayersForDropdown();
    }, [])

    // update form fields any time dropdown state changes
    useEffect(() => {
        const fieldsCopy = {
            ...formFields
        };

        fieldsCopy.relatedPlayer = selectedPlayer.name ? {
            name: selectedPlayer.name, 
            id: selectedPlayer.id,
            playerCoach: selectedPlayer.playerCoach
        } : null;

        setFormFields(fieldsCopy);
    }, [selectedPlayer])

    // clear out selected player if "related connected to player" is unchecked
    useEffect(() => {
        if (!formFields.isConnectedToPlayer) {
            setSelectedPlayer({name: "", id: "", playerCoach: ""});

            const fieldsCopy = {
                ...formFields
            };    
            fieldsCopy.relationToPlayer = "";
            setFormFields(fieldsCopy);
        }
    }, [formFields.isConnectedToPlayer])

     // update form object any time profile pic is updated
     useEffect(() => {
        const fieldsCopy = {
            ...formFields
        };

        // the only field we care to send to the server is the base64 string of the new image
        fieldsCopy.newProfileImgBase64 = profilePic.profileImageDataTemp.base64;
        setFormFields(fieldsCopy);
    }, [profilePic])

    function handleFieldChange(e) {
        const propertyName = e.target.name;
        const propertyValue = e.target.type == "checkbox" ? e.target.checked : e.target.value;
        const fieldsCopy = {
            ...formFields
        };
        fieldsCopy[propertyName] = propertyValue;
        setFormFields(fieldsCopy);
    }

    function handlePermissionsChange(perms) {
        const fieldsCopy = {
            ...formFields
        };
        fieldsCopy.permissions = convertPermissionsForSubmit(perms);
        setFormFields(fieldsCopy);
        setPermissions(perms);
    }

    function handleSocialsChange(e) {
        const propertyName = e.target.name;
        const propertyValue = e.target.type == "checkbox" ? e.target.checked : e.target.value;
        const fieldsCopy = {
            ...formFields
        };
        fieldsCopy.socials[propertyName] = propertyValue;
        setFormFields(fieldsCopy);
    }

    function handlePlayerChange(event) {
        let dropdown = event.target;
        let id = dropdown.value;
        let name = dropdown.options[dropdown.selectedIndex].text;
        let playerCoach = dropdown.options[dropdown.selectedIndex].dataset.customvalue1;

        // update selected player state
        setSelectedPlayer({name: name, id: id, playerCoach: playerCoach});       
    }

    const handleEditProfilePicClick = (e) => {
        setCropperIsActive(true);
    }

    const handleExitCropperclick = (e) => {
        setCropperIsActive(false);
    }

    const handleUpdatePhotoClick = (photoData) => {
        setCropperIsActive(false);

        let newProfileData = {...profilePic};
        newProfileData.profileImageDataTemp = {
            base64: photoData.base64,
            blob: photoData.blob
        };
        setProfilePic(newProfileData);
    }

    // submit edit / create 
    const handleSubmitClick = async () => {
        setSubmitting(true);
        const accessToken = await getAccessTokenSilently();
        let contactCreated = await createUpdateContact(accessToken, formFields, contact?.id ?? null);
        
        if (!contactCreated.error) {
            toast.success(`User ${editMode ? "updated" : "created"}!`);
            !editMode && resetState();
        }
        else {
            toast.error(`Contact ${editMode ? "update" : "creation"} failed: ${contactCreated.error.message}`);
        }

        setSubmitting(false);
    }
    
    // submit delete 
    const handleDeleteClick = async () => {
        setDeleting(true);
        const accessToken = await getAccessTokenSilently();
        let contactDeleted = await deleteContactById(accessToken, contact?.id);
        
        if (!contactDeleted.error) {
            toast.success(`User Deleted!`);
            resetState();
            window.location = '/Contacts/All';
        }
        else {
            toast.error(`Contact deletion failed: ${contactDeleted.error.message}`);
        }

        setDeleting(false);
    }   

    return (
        <div className="admin-panel-outer">
            <div className="contact-edit-headshot-title">
                { editMode && <a className="backBtn" href={`/contacts/${contact.id}`}><i className="fa-regular fa-arrow-left"></i></a> }
                <ProfilePic src={profilePic.profileImageDataTemp.blob ?? contact?.profileImageURL ?? null} placeholderInitials={contactInitials} handleEditClick={handleEditProfilePicClick} />
                <div className="admin-panel-header">{ editMode ? "Edit" : "Create a New" } Contact</div>
            </div>            
            <ProfilePicUpdater
                 src={profilePic.profileImageDataTemp.blob ?? contact?.profileImageURL ?? null}
                 handleExitCropperclick={handleExitCropperclick} 
                 isActive={cropperIsActive}
                 handleSubmitClick={(photoData) => handleUpdatePhotoClick(photoData)} 
            />
            <CreateContactField value={formFields.firstName} id="firstNameField" handleValueChange={handleFieldChange} propName="firstName" label="First Name" />
            <CreateContactField value={formFields.lastName} id="lastNameField" handleValueChange={handleFieldChange} propName="lastName" label="Last Name" />
            {!editMode &&
                <CreateContactField value={formFields.email} id="emailField" handleValueChange={handleFieldChange} propName="email" label="Email" /> 
            }
            <CreateContactField value={formFields.phone} id="phoneField" handleValueChange={handleFieldChange} propName="phone" label="Phone" />
            <CreateContactField value={formFields.socials.twitter} id="twitterField" handleValueChange={handleSocialsChange} propName="twitter" label="Twitter" placeholder="e.g. @dbacks" />
            <CreateContactField value={formFields.socials.insta} id="instaField" handleValueChange={handleSocialsChange} propName="insta" label="Instagram" placeholder="e.g. @dbacks" />
            <CreateContactField value={formFields.socials.tikTok} id="tikTokField" handleValueChange={handleSocialsChange} propName="tikTok" label="TikTok" placeholder="e.g. @dbacks" />
            {currentUserAdmin &&
                <CreateContactField type="checkbox" value={formFields.isConnectedToPlayer} id="isConnectedToPlayerField" handleValueChange={handleFieldChange} propName="isConnectedToPlayer" label="Is this contact related/connected to a player/coach?" />
            }
            { formFields.isConnectedToPlayer && currentUserAdmin &&
            <>
                <Dropdown 
                    id="playerSelectDropdown" 
                    value={selectedPlayer?.id ?? -1}
                    dropdownValues={dbacksPlayers} 
                    handleDropdownChange={handlePlayerChange} 
                    label="Related Player" 
                    className="form-fieldgroup"
                />
                <CreateContactField value={formFields.relationToPlayer} id="relationToPlayerField" handleValueChange={handleFieldChange} propName="relationToPlayer" label="Relation To Player" />
            </>
            }
            { !formFields.isConnectedToPlayer && currentUserAdmin &&
                <CreateContactField value={formFields.alternateDescription} id="alternateDescriptionField" handleValueChange={handleFieldChange} propName="alternateDescription" label="Description" />
            }
            { !editMode && currentUserAdmin &&
                <>
                    <CreateContactField type="checkbox" value={formFields.isSiteUser} id="isSiteUserField" handleValueChange={handleFieldChange} propName="isSiteUser" label="Invite Contact To Join D-backs Families?" />
                    <CreateContactField value={formFields.hideContactFromOthers} type="checkbox" id="hideContactFromOthersField" handleValueChange={handleFieldChange} propName="hideContactFromOthers" label="Hide this contact from other members?" />
                </>                
            }

            { currentUserAdmin && 
                <Select 
                    options={getAvailablePermissions()}
                    isMulti={true}
                    placeholder="Select permissions"
                    value={permissions}
                    onChange={handlePermissionsChange}
                    className="multiselect-dropdown"
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                        ...theme.colors,
                        primary25: 'gray',
                        primary: '#222222',
                        neutral0: "#222222",
                        neutral10: "white",
                        neutral20: "gray"
                        },
                    })}
                    styles={{
                        multiValueLabel: (baseStyles) => ({
                            ...baseStyles,
                            fontSize: "15px",
                            fontWeight: "400"
                        }),
                        multiValueRemove: (baseStyles) => ({
                            ...baseStyles,
                            color: "black",
                        })
                    }}
                />
            }

            <div className="contact-update-delete-row" style={{width: "100%", marginTop:"12px"}}>
                {
                    currentUserAdmin && editMode && 
                    <button id="deleteContactBtn" onClick={() => setShowModal(true)} className="btn btnDelete">
                        <i className="fa-solid fa-trash"></i><span>Delete</span>
                    </button>
                }                
                <button id="submitUpdateBtn" onClick={handleSubmitClick} className="btn btn1">
                    {isSubmitting ? <Loader /> : <><span>{ editMode? "Update" : "Create"} { !currentUserAdmin? " Contact" : ""}</span><i className="fa-regular fa-arrow-right"></i></> }
                </button>
            </div>

            <AreYouSureModal onCompleteAction={handleDeleteClick} handleCancelAction={() => setShowModal(false)} loadingState={isDeleting} isVisible={showModal}/>
            
        </div>
    )
}