import React from "react";
import "../../styles/components/contact.css"
import { formatPhoneNumber } from "../shared/utilities";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { ProfilePic } from "./profile-pic";

export default function Contact({contact, showFullVersion = true, viewerIsAdmin = false, viewingOwnProfile = false}) {
    const sizingClass = showFullVersion ? "fullSize" : "miniSize";
    const navigate = useNavigate();

    const descriptionSection = contact?.relationToPlayer ? 
        <>
            <span>{contact?.relationToPlayer} of </span> 
            <a target="_blank" href={`https://www.mlb.com/player/${contact?.relatedPlayer?.id}`} style={{textDecoration: "underline", color: "inherit"}}>{contact?.relatedPlayer?.name}</a>
        </> :
        <span>{contact?.alternateDescription}</span>

    function handleEditContactClick() {
        navigate(`/contacts/edit/${contact?.id}`);
    }

    return (
        <a href={!showFullVersion ? `/contacts/${contact.id}` : "javascript:void(0);"} className={`contact-panel-outer ${sizingClass}`}>            
            <div className="contact-name">
                <ProfilePic 
                    src={contact?.profileImageURL} placeholderInitials={contact == null ? "" : contact?.firstName[0].toUpperCase() + contact?.lastName[0].toUpperCase()} 
                    editable={false}
                />
                <div className="contact-header-text">
                    <div className="contact-header-name">
                        <span>{contact?.firstName}</span>
                        <span>  </span>
                        <span>{contact?.lastName}</span>
                    </div>                    
                    <div className="contact-subhead">
                        {descriptionSection}
                    </div>
                </div>                
                <i className="fa-sharp fa-solid fa-crown contact-diamond"></i>
            </div>
            {showFullVersion && 
                <>
                <div className="contact-infoblock">
                    <div className="contact-contactinfo">
                        <div className="contact-icon-shell">
                            <i className="fa-solid fa-phone"></i>
                        </div>
                        <a href={ contact?.phone == "" ? '#' : 'tel:+' + contact?.phone} >{contact?.phone == "" ? "No phone" : formatPhoneNumber(contact?.phone)}</a>
                    </div>
                    <div className="contact-contactinfo">
                        <div className="contact-icon-shell">
                            <i className="fa-solid fa-envelope"></i>
                        </div>
                        <a href={`mailto:${contact?.email}`}>{contact?.email}</a>
                    </div>
                    
                </div>
                <div className="contact-infoblock">
                    <div className="contact-contactinfo">
                        <div className="contact-icon-shell">
                            <i className="fa-brands fa-twitter"></i>
                        </div>
                        <a target={!contact?.socials?.twitter ? null : "_blank"} href={!contact?.socials?.twitter ? "#" : `https://twitter.com/@${contact?.socials?.twitter}`}>
                            {!contact?.socials?.twitter ? "None" : "@" + contact?.socials?.twitter}
                        </a>  
                    </div>
                    <div className="contact-contactinfo">
                        <div className="contact-icon-shell">
                            <i className="fa-brands fa-instagram"></i>
                        </div>
                        <a target={!contact?.socials?.insta ? null : "_blank"} href={!contact?.socials?.insta ? "#" : `https://instagram.com/${contact?.insta}`}>
                            {!contact?.socials?.insta ? "None" : contact?.socials?.insta}
                        </a>
                    </div>
                    <div className="contact-contactinfo">
                        <div className="contact-icon-shell">
                            <i className="fa-brands fa-tiktok"></i>
                        </div>
                        <a target={!contact?.socials?.tikTok ? null : "_blank"} href={!contact?.socials?.tikTok ? "#" : `https://tiktok.com/@${contact?.tikTok}`}>
                            {!contact?.socials?.tikTok ? "None" : "@" + contact?.socials?.tikTok}
                        </a>
                    </div>
                </div>                 
                { (viewerIsAdmin || viewingOwnProfile) && 
                    <button className="contact-edit-btn btn" onClick={handleEditContactClick}>
                        <i className="fa-solid fa-pencil"></i>
                        <span className="contact-edit-text">Edit</span>
                    </button> 
                }
                </>
            }
            { !showFullVersion && 
             <div className="contact-chevron-right">
                <i className="fa-duotone fa-chevron-right"></i>
             </div> 
            }
        </a>
    )
}