import React, { useState, useEffect } from "react";
import { PageLayout } from "../components/page-layout";
import JerseyFormAdmin from "../components/forms/form-admin-jersey";

export const JerseyAdminPage = () => {

    return (
        <PageLayout>
            {<JerseyFormAdmin/> }
        </PageLayout>
    )
} 